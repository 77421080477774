.footer1{
	border-top: solid 3px $colorPrimary;
	width: 100%;
	display: block;
	background-color: $colorTerciary;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;

	& .logo_footer{
		display: inline-block;
		vertical-align: top;
		width: 100%;

		& a{
			display: block;
			width: fit-content;
			& .img{
				display: block;
				width: calc(350px / 1);
				height: calc(200px / 1);
				margin: 0;

				& img{
					width: calc(350px / 1);
					height: calc(200px / 1);
				}
			}
		}
	}

	& .rs_footer{
		display: block;
		width: 100%;
		padding-left: 1rem;

		& h3{
			font-size: 1rem;
			font-weight: 600;
			color: $gray5;
		}

		& a{
			margin: 0;
			display: inline-block;
			vertical-align: middle;
			border-right: none;
			padding: 0;
			
			& p{
				margin: 0;
				& i{
					color: $gray5;
					padding: .5rem;
					transition: all .2s;
				}
			}

			&:hover{
				& p{
					& i{
						color: $colorPrimary;	
					}
				}
				border-right: none;
				padding-right: none;
			}
		}
	}

	& .contatofooter{
		display: block;
		width: 100%;
		padding-left: 1rem;
		
		& .tel_email_footer{
			display: inline-block;
			width: 100%;
			vertical-align: top;

			& h3{
				font-size: 1rem;
				font-weight: 600;
				color: $gray5;
			}
			
			& a{
				display: block;
				width: fit-content;
				& p{
					font-size: .9rem;
					font-weight: 400;
					color: $gray5;
					transition: all .2s;
					&:hover{
						color: $colorPrimary;
					}
				}
			}
		}
	}
	
	& .menu_footer{
		display: block;
		width: 100%;

		& a{
			display: block;
			width: fit-content;

			& p{
				font-size: 1rem;
				font-weight: 500;
				color: $gray5;
				transition: all .2s;
				&:hover{
					color: $colorPrimary;
				}
			}
		}
	}

	& .endereco_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: 1rem;
			font-weight: 600;
			color: $gray5;
		}

		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray5;

			& i {
				color: $colorPrimary;
			}
		}

		& a{
			width: fit-content;
			display: block;
			margin: 0;
			padding: 0;
			& p{
				margin: 0;
				padding: 0;
				font-size: .85rem;
				font-weight: 400;
				color: $colorPrimary;
			}
		}
	}

	& .horarioatendimento_footer{
		display: block;
		width: 100%;

		& h3{
			font-size: 1rem;
			font-weight: 600;
			color: $gray5;
			margin: 0;
		}

		& p{
			font-size: .9rem;
			font-weight: 400;
			color: $gray5;
		}
	}

	& .copyright_footer{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& p{
			text-align: left;
			color: white;
			font-size: .8rem;
			font-weight: 400;
			& strong{
				color: white;
			}
		}
	}

	& .devby{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& .developed{
			display: inline-block;
			vertical-align: middle;
			width: 60%;

			& p{
				text-align: right;
				color: white;
				font-size: .8rem;
				font-weight: 400;
			}
		}

		& a{
			display: inline-block;
			vertical-align: middle;
			width: 15%;

			& .logo{
				width: 4rem;
				& img{
					width: 100%;
				}
			}

			& .logo2{
				width: 7rem;
				& img{
					width: 100%;
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	
	.footer1{
		& .logo_footer{
			& a{
				& .img{
					width: calc(350px / 1.3);
					height: calc(200px / 1.3);
	
					& img{
						width: calc(350px / 1.3);
						height: calc(200px / 1.3);
					}
				}
			}
		}

		& .devby{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& .developed{
				display: inline-block;
				width: 50%;
				vertical-align: middle;

				& p{
					text-align: right;
					color: $gray4;
					font-size: .8rem;
					font-weight: 400;
				}
			}

			& a{
				display: inline-block;
				vertical-align: middle;
				width: 25%;

				& .logo{
					width: 5rem;
					& img{
						width: 100%;
					}
				}

				& .logo2{
					width: 9rem;
					& img{
						width: 100%;
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	.footer1{
		background-color: darken($gray1, 5);

		& .logo_footer{
			& a{
				width: 100%;

				& .img{
					width: calc(350px / 1.2);
					height: calc(200px / 1.2);
					margin: auto;
	
					& img{
						width: calc(350px / 1.2);
						height: calc(200px / 1.2);
					}
				}
			}
		}
		
		& .rs_footer{
			padding-left: 0;
		}

		& .contatofooter{
			padding-left: 0;
		}

		& .copyright_footer{
			& p{
				text-align: center;
				font-size: .9rem;
			}
		}

		& .devby{
			& .developed{
				display: block;
				width: 100%;

				& p{
					text-align: center;
					font-size: .9rem;
				}
			}

			& a{
				width: 50%;

				& .logo{
					width: 6rem;
					& img{
						width: 100%;
						height: 84px;
					}
				}

				& .logo2{
					width: 9rem;
					& img{
						width: 100%;
						height: 52px;
					}
				}
			}
		}
	}
}
/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}