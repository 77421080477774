.section_imobiliaria{
    display: block;
    width: 100%;

    & h1{
        font-size: 2rem;
        font-weight: 600;
        color: $colorTerciary;
    }

    & h2{
        font-size: 2rem;
        font-weight: 600;
        color: $colorTerciary;
    }

    & p{
        font-size: .9rem;
        font-weight: 400;
        color: black;
    }

    & .section_equipe{
        display: block;
        width: 100%;
    
        & .miniatura_equipe{
            display: block;
            user-select: none;
            width: 100%;
            padding: .8rem;
            border: solid 1px lighten($gray4, 10);
            
            & .img{
                //background-color: $gray5;
                display: block;
                width: 100%;
                text-align: center;
                padding: 0;
                
                & img{
                    width: 100%;
                    border-radius: 50%;
                    border: solid 3px $gray4;
                }
            }
    
            & .detalhes{
                display: block;
                width: 100%;
    
                & .nome{
                    font-size: 1rem;
                    font-weight: 600;
                    color: $colorTerciary;
                    text-transform: uppercase;
                }
        
                & p{
                    font-size: .8rem;
                    font-weight: 400;
                    color: $gray2;
                    word-wrap: break-word;
                }

                & a{
                    width: fit-content;
                    display: block;

                    & p{
                        transition: all .2s;
                    }
                    
                    &:hover{
                        & p{
                            color: $colorTerciary
                        }
                    }
                }
            }
        }
    }
}