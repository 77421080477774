.nav {
	display: block;
	width: 20vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: $colorTerciary;
	z-index: 99991;
	padding: 0 1rem;
	box-shadow: -5px 0 10px -5px rgba(white, .2);

	& button {
		margin-right: 0;
		position: absolute;
		right: .75rem;
		top: .75rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: white;
		font-size: 1.2rem;
		font-weight: 600;
		padding: 1.5rem 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: white;
		font-size: 1rem;
		margin: 0;
		padding: .6rem 1rem;
		@include border-box;
		border-bottom: solid 2px darken($gray1, 10);
		transition: all .25s;
		border-radius: .5rem;

		&:hover {
			transition: all .25s;
			background-color: $colorPrimary;
			color: $colorTerciary;
			padding: .6rem 1.5rem;
			border-color: transparent;
		}
	}
	& .redes_sociais_navmenu{
		display: block;
		width: 100%;
		padding: 1rem 0;
		& a{
			display: inline-block;
			width: 15%;
			vertical-align: middle;
			& i{
				font-size: 2rem;
				font-weight: 500;
				padding: .5rem;
				border: solid 2px $colorSecondary;
				border-radius: .1rem;
				color: $colorPrimary;
				transition: all .15s;
				&:hover{
					transform: scale(1.1);
				}
			}
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){
	.nav {
		width: 25vw;
	}
}

@media all and (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		background-color: rgba($colorTerciary, .99);
		
		& .nav-menu {
			font-size: 1.2rem;
		}
		
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}