.title_condomi{
	text-align: center;
	
	& h2{
		font-size: 1.8rem;
		font-weight: 600;
		color: $colorTerciary;
		margin: 0;
		padding-bottom: .5rem;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
		border-radius: 2rem;
	}
}

.title_condomi2{
	text-align: center;
	
	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: white;
	}

	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.blog_section{
	width: 100%;
	display: block;
	& h2{
		font-size: 2.3rem;
		font-weight: 600;
		color: $colorTerciary;
		text-align: center;
	}
	& .under_p{
		text-align: center;
		width: 4.5rem;
		height: 4px;
		margin: auto;
		background-color: $colorPrimary;
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background-color: $colorTerciary;
	& a{
		display: inline-block;
		width: 100%;
		vertical-align: top;
		padding: 1.5rem 2.5rem;
		border-radius: .2rem;
		transition: all .2s ease-in-out;
		border: solid 2px transparent;
		& .bloco_servico{
			width: 100%;
			display: block;
			text-align: left;

			& .img{
				width: 3rem;
				display: block;
				margin: 0;
				& img{
					transition: all .15s;
					text-align: left;
					width: 100%;
				}
			}
			& h2{
				color: $gray5;
				font-size: 1.1rem;
				font-weight: 600;
				text-align: left;
			}
			& p{
				padding: 0;
				color: $gray5;
				font-size: .9rem;
				font-weight: 400;
				text-align: left;	
				transition: all .15s;
			}
		}

		&:hover{
			border: solid 2px $colorPrimary;
		}
	}
}

.bg_busca_interna{
	background-color: lighten($colorTerciary, 2);
	background-image: url('/jnh/background/bg_footer.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
	border-bottom: solid 3px $colorPrimary;

	& h2{
		text-align: center;
		color: white;
		font-size: 1.8rem;
		font-weight: 400;
		margin: 0;
	}


	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 2rem 1rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray5;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: .5rem;
			}
		}
	}
}

.section_sobre {
	background: linear-gradient(110deg, $gray5 70%, $colorPrimary 70%);
	background-color: white;
	display: block;
	width: 100%;

	& .container_sobre {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 2rem;
		align-items: center;

		& .conteudo {
			width: 80%;
			margin: 0 auto;

			& h3 {
				font-size: 1.3rem;
				font-weight: 600;
				color: $colorTerciary;
				margin: 0;
			}

			& h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: $colorPrimary;
			}

			& p {
				font-size: 1rem;
				font-weight: 400;
				color: $gray2;
			}

			& strong {
				font-size: 1rem;
				font-weight: 400;
				color: $gray1;
			}

			& .btn_vermais {
				width: 100%;
				margin: 0;

				& a {
					width: fit-content;
					display: block;
					margin: 0;

					& p {
						background-color: $gray1;
						color: white;
						font-size: 1rem;
						font-weight: 600;
						padding: .3rem 1.5rem;
						border-radius: 2rem;
						transition: background-color .2s ease-in-out;
						border: solid 1px $colorPrimary;

						& i {
							color: $colorPrimary;
							font-size: 1.2rem;
							vertical-align: middle;
						}

						&:hover {
							background-color: darken($gray1, 5);
						}
					}
				}
			}

			& .infos_sobre {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem 3rem;

				& .info_imob{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
			
					& .img{
						width: calc(256px / 6.5);
						height: calc(256px / 6.5);
						display: inline-block;
						vertical-align: middle;
			
						& img{
							width: calc(256px / 6.5);
							height: calc(256px / 6.5);
						}
					}
			
					& .descricao{
						width: 100%;
			
						& .title{
							margin: 0;
							font-size: .9rem;
							color: $colorTerciary;
							font-weight: 600;
							margin: 0;
						}
						
						& p{
							padding-left: 1rem;
							color: $gray2;
							font-size: .9rem;
							font-weight: 400;
							margin: 0;
						}
			
						& a{
							width: fit-content;
							display: block;
							margin: 0;

							& p{
								color: $gray2;
								font-size: .9rem;
								font-weight: 400;
								margin: 0;
								background-color: transparent;
							}
						}
					}
				}
			}

		}

		& .imagem_sobre {
			width: 100%;
			margin: 0;
			text-align: center;

			& img {
				border-radius: 1rem;
				width: calc(756px / 1.5);
				height: calc(945px / 1.5);
				-webkit-user-drag: none;
			}
		}
	}
}

#ux_to_top{
	position: relative;
	top: -100px;
	left: -60%;
	z-index: 999999;
}

.botao_voltar{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;
	padding: .3rem 1rem;
	color: $colorTerciary !important;
	font-size: 1rem;
	font-weight: 500;
	cursor: pointer;
	transition: border-color .2s ease-in-out;

	& i {
		color: $colorPrimary;
		transition: color .2s ease-in-out;
	}

	&:hover {
		border-color: $gray4;
		& i {
			color: darken($colorPrimary, 10);
		}
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorTerciary;
	vertical-align: middle;
	
	& i{
		color: $colorPrimary;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.section_sobre {
		background: linear-gradient(110deg, $gray5 70%, $colorPrimary 70%);
		& .container_sobre {
			gap: 1rem;
	
			& .conteudo {
				width: 85%;
	
				& p {
					font-size: .9rem;
				}
	
				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				& img {
					width: calc(756px / 2);
					height: calc(945px / 2);
				}
			}
		}
	}
}

@media all and (max-width: 1023px){

	.section_sobre {
		background: linear-gradient(165deg, $colorPrimary 25%, $gray5 25%);
		
		& .container_sobre {
			display: flex;
			flex-direction: column;
			flex-direction: column-reverse;
			gap: 1rem;
			grid-template-columns: none;
			

			& .conteudo {
				width: 100%;

				& h3 {
					font-size: 1.2rem;
				}
	
				& h2 {
					font-size: 1.3rem;
				}
	
				& p {
					font-size: .9rem;
				}
	
				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					grid-template-columns: 1fr;
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				text-align: center;
				& img {
					width: calc(756px / 2.5);
					height: calc(945px / 2.5);
					max-width: 90vw;
					margin: auto;
				}
			}
		}
	}

	.bg_busca_interna{
		background-color: $colorTerciary;
		background-image: none;
		background-repeat: none;
		background-size: none;
		background-position: none;
		border-bottom: none;
		& h2{
			font-size: 1.4rem;
		}
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.filtros_avancados_e_ordenar {
		flex-direction: column;
		gap: 2rem;
		
		& .filtros_ord {
			width: 100%;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			gap: 0;
			margin: 0;
		}
	}

	.page-wrap .width-50, .main-content, .main-content2{
		width: 100%;
	}

	#ux_to_top{
		left: 20%;
		top: -80px;
	}

	.title_condomi{
		text-align: center;
		
		& h2{
			font-size: 1.5rem;
			font-weight: 600;
			color: $colorTerciary;
		}

		& p{
			font-size: 1rem;
			font-weight: 400;
			color: $gray3;
			text-align: center;
		}

		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 3px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.blog_section{
		width: 100%;
		display: block;
		& h2{
			font-size: 2rem;
			font-weight: 600;
			color: $colorTerciary;
			text-align: center;
		}
		& .under_p{
			text-align: center;
			width: 4.5rem;
			height: 4px;
			margin: auto;
			background-color: $colorPrimary;
		}
	}

	.section_servicos{
		width: 100%;
		display: block;

		& a{

			& .bloco_servico{
	
				& .img{
					width: 15%;
					& img{
						width: 100%;
						height: 37px;
					}
				}
	
				& .underline_x{
					&::after{
						left: 5%;
					}
				}
			}

			&:hover{
				& .img{
					& img{
						transform: scale(1.1);
					}
				}
	
				& .underline_x{
	
					&::after{
						background-color: $colorPrimary;
						width: 1%;
						height: 100%;
					}
				}
			}
		}

	}
}
.input-novidades:-webkit-autofill,
.input-novidades:-webkit-autofill:hover, 
.input-novidades:-webkit-autofill:focus, 
.input-novidades:-webkit-autofill:active  {
    -webkit-box-shadow:0 0 0 50px rgba(0,0,0,0) inset !important;
    -webkit-text-fill-color: white !important;
}

@media all and (min-width: 320px) and (max-width: 1023px){
	.max-sz-con{
		max-width: 40rem !important;
		padding-left: 2rem !important;
		padding-right: 1rem !important;
	}
}