.formsimple-selectmultiple{
	&::after {	
		cursor: pointer;
		position: absolute;
		top: -6px;
		right: 15px;
		pointer-events:none;
		bottom: 0;
		display: block;
		z-index: 1;
		text-align: center;
		width: 1rem;
		height: 0rem;
		line-height: #{$formsimple_inputHeight - ($formsimple_inputBorder * 2)}px;
		content: "\f078";
		font-size: 14px !important;
		padding: .7rem 0;
		color: $colorPrimary;
		font-weight: 600;
		font-family: 'Objetiva Software Light' !important;
		transform: rotate(0deg);
		transform-origin: center;
		transition: transform 0.15s ease;
	}

	& .label {
		background-color: $gray1;
		color: white !important;
		font-size: .9rem !important;
		font-weight: 400 !important;
		border: solid 1px $colorPrimary;
		border-radius: .5rem;
		padding:  .2rem 1rem;
		text-align: left;
		transition: all .2s;
		&:hover{
			background-color: darken($gray1, 5);
		}
	}
	& .component {
		border: 1px solid $colorPrimary !important;
		z-index: 10;
		& .items{
			overflow: auto;
			& .group{
				color: $gray5 !important;
			}
		}

	}

}
