.header1{
	background-color: $colorTerciary;
	width: 100%;
	z-index: 99999999;
}

.box_header{
	box-shadow: 0 0 20px 1px rgba(black, .05);
	z-index: 999;
	position: relative;
}


#sub_menu_vendas{
	display: none;
	width: 230px;
	max-width: 230px;
	max-height: 300px;
	overflow: auto;
	position: absolute;
	left: 50%;
	top: 2.4rem;
	transform: translate(-50%);
	background-color: $colorTerciary;
	border: solid 1px $gray1;
	border-radius: .5rem;
	z-index: 3;
	& .elementos_sub_menu{
		text-align: left;
		overflow: auto;
		max-height: 30rem;
		padding: .5rem 0;
		& p{
			font-size: .9rem !important;
			font-weight: 500;
			background-color: none;
			padding: 0.5rem 1.5rem;
			margin: 0;
			text-align: left;
			transition: all .2s;
			color: white;

			&:hover{
				background-color: $colorPrimary;
				color: $colorTerciary;
				padding-left: 2rem;
			}
		}
		& .titletipo{
			color: white;
			font-weight: 600;
			line-height: 1.3em;
		}

		& .itemtipo{
			color: white !important;
			line-height: 1.3em;
			&:hover{
				font-weight: 600;
			}
		}
	}
}

.headerpc {
	display: block;
	width: 100%;
	position: relative;
	padding: .5rem 0;

	& .superior {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		width: 100%;
		color: #ffffff;
		z-index: 3;
		box-sizing: border-box;

		& .header_left {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 2rem;
			margin: 0;

			& .logo {
				display: block;
				width: fit-content;
				margin-left: 0;
				@include border-box;
	
				& .img{
					width: calc(350px / 2);
					height: calc(200px / 2);
					display: block;
					
					& img {
						width: calc(350px / 2);
						height: calc(200px / 2);
					}
				}
			}
	
			& .sessao_creci {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				gap: 2rem;
				width: fit-content;
				margin: 0;
	
				& .corretor_header {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					

					& .nome_corretor_header {
						color: white;
						font-family: 'Elsie';
						font-size: .95rem;
						font-weight: 400;
						margin: 0;
						line-height: .75rem;
					}

					& span {
						font-size: .75rem;
						font-weight: 500;
						color: $colorPrimary;
						margin: 0;
					}
				}
			}
		}

		& .sessions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 3rem;
			margin: 0;
			
			& ul {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 3rem;
				margin: 0%;
				padding: 0%;
				list-style: none;
				transition: all .25s ease;
				
				& .itens_header{
					& a{
						color: white;
						font-weight: 400;
						font-size: .95rem;
						transition: all .2s;

						& i {
							font-size: 1.1rem;
							vertical-align: middle;
						}

						&:hover{
							color: $colorPrimary;
						}
					}

					& .itemsession{
						position: relative;
						width: 100%;
	
						& .title_is{
							width: 100%;
	
							& .itemsession_title{
								font-size: 1rem;
								color:  white;
								text-align: center;
								text-transform: initial;
								font-weight: 400;
								padding: .5rem 0;
								@include border-box;
								transition: all .2s;
								& i{
									vertical-align: middle;
									font-size: .65rem;
									color:  $colorPrimary;
									font-weight: 300;
								}
							}
						}
	
						&:hover{
							#sub_menu_vendas {
								display: initial;
							}
						}
						
					}
				}
			}
			
			& .menu {
				display: block;
				width: fit-content;
				margin: 0;
			}
		}
	}
}

.headerindex {
	height: calc(100vh - 7.2rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;

		& .bg-headerpc{
			display: block;//era block
			height: calc(100vh + 1px);
			min-height: 40rem;
			width: 100%;
			z-index: 2;
			background-color: $gray1;
			background-image: url('/jnh/header/bg_header3.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: top;
			animation-name: bgheaderpc;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			animation-duration: 25s;
		}

		@include keyframes('bgheaderpc'){
			0% {
				background-image: url('/jnh/header/bg_header3.jpg');
			}
			10% {
				background-image: url('/jnh/header/bg_header3.jpg');
			}
			20%{
				background-image: url('/jnh/header/bg_header4.jpg');
			}
			40%{
				background-image: url('/jnh/header/bg_header4.jpg');
			}
			50% {
				background-image: url('/jnh/header/bg_header5.jpg');
			}
			60% {
				background-image: url('/jnh/header/bg_header5.jpg');
			}
			70% {
				background-image: url('/jnh/header/bg_header6.jpg');
			}
			90% {
				background-image: url('/jnh/header/bg_header6.jpg');
			}
		}
	}
}

.header-mob{
	background-color: $colorTerciary;
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	width: 100%;

	& .log{
		width: calc(350px / 3);
		height: calc(200px / 3);
		display: block;
		margin: 0;
		
		& img {
			width: calc(350px / 3);
			height: calc(200px / 3);
		}
	}

	& .btn-men{
		width: fit-content;
		margin: 0;
		text-align: right;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		gap: 1rem;

		& p {
			font-size: .8rem;
			font-weight: 400;
			color: white;
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.headerindex {
		height: calc(100vh - 6.8rem);
	}

	.headerpc {
		& .superior {
			/* GRUPO HEADER */
			& .header_left {
				& .logo {
					& .img{
						width: calc(350px / 2.2);
						height: calc(200px / 2.2);
						display: block;
						
						& img {
							width: calc(350px / 2.2);
							height: calc(200px / 2.2);
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 1023px){
	
	.headerindex {
		height: calc(100vh - 5.7rem);
		display: block;
		width: 100%;
		padding: 0;
		@include border-box;
		z-index: 2;
		background-color: darken($gray1, 5);
		background-image: inherit;
		background-attachment: fixed;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: left;
		
		& h1{
			text-align: center;
			font-size: 1.2rem;
			font-weight: 400;
			color: white;
		}

		& .container-bg-headerpc{
			display: block;
			position: absolute;
			top:0;
			overflow: hidden;
			z-index: -1;
			height: 100%;
			width: 100%;
		}
	}
}
