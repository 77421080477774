.busca {
	display: block;
	width: 65%;
	text-align: center;
	position: absolute;
	top: 90%;
	left: 50%;
	transform: translate(-50%, -90%);
	z-index: 9997;

	& h1 {
		color: white;
		text-shadow: 1px 1px 3px black;
		font-size: 2rem;
		font-weight: 500;
		margin: 0;
	}

	& h2 {
		color: white;
		text-shadow: 1px 1px 3px black;
		font-size: 1.3rem;
		font-weight: 500;
		margin-bottom: 1rem;
	}

	& .busca-filtro{
		width: 100%;
		display: block;

		& .bloco_busca{
			& .container_filtros {
				background-color: rgba($colorTerciary, .5);
				border-radius: .5rem;
				padding: 1rem;
				box-sizing: border-box;
				display: grid;
				grid-template-columns: 1fr 1fr 1.2fr .7fr .7fr;
				gap: 1rem;

				& .item_buscahome {
					width: 100%;
					
					& .btn-home{
						padding: .85rem 0;
						border-radius: .5rem;
						width: 100%;
						display: inline-block;
						vertical-align: middle;
						background-color: $colorPrimary;
						color: $colorTerciary;
						font-size: 1rem;
						font-weight: 600;
						border: none;
						cursor: pointer;
						transition: background-color .2s ease-in-out;

						&:hover{
							background-color: darken($colorPrimary, 5);
						}
					}
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .6rem 0;
	border: solid 1px $colorPrimary;
	border-radius: .5rem;
	margin-top: .15rem;
	background-color: $colorPrimary;
	color: $colorTerciary;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .6rem 0;
	border: solid 1px $colorPrimary;
	border-radius: .5rem;
	margin-top: .15rem;
	background-color: $gray1;
	color: $gray4;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	&:hover{
		background-color: darken($gray1, 5);
	}
}

.btns_busca_filtros_mob {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .5rem;
}

.btn_padrao{
	background-color: white;
	padding: .5rem 1rem;
	color: $gray2;
	font-size: .9rem;
	font-weight: 600 !important;
	cursor: pointer;
	transition: all .2s ease-in-out;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;

	& i {
		color: $colorPrimary;
	}
	
	&:hover{
		border-color: $colorPrimary;
	}
}

.btn_padrao_primary {
	background-color: $colorPrimary;
	padding: .5rem 1rem;
	color: $colorTerciary;
	font-size: .9rem;
	font-weight: 600 !important;
	cursor: pointer;
	border: solid 1px $colorPrimary;
	border-radius: .3rem;
	transition: all .2s ease-in-out;
	
	&:hover{
		background-color: darken($colorPrimary, 5);
	}
}

#render-busca {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;

	& .miniatura-imovel {
		width: 100% !important;
	}
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: .3rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorPrimary;
			top: 40%;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: lighten($gray5, 2);
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			& span{
				width: 13px !important;
				height: 13px !important;
				float: none !important;
				border-radius: 50% !important;
			}
		}
	}
}

.btns_ver_resultados_infras {
	display: block;
	width: 100%;
	padding-top: 1rem;

	& .btn_pc_Infras {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

.filtros_avancados_e_ordenar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	& .filtros_ord {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
		margin: 0;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px){

	.busca {
		width: 80%;
	}
}

@media all and (max-width: 1023px){

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}
	
	.btn_mostrar_mais_busca_interna{
		text-align: center;
		width: 50%;
		background: linear-gradient(to bottom right, $colorPrimary, $colorSecondary);
		color: white;
		font-size: 1rem;
		font-weight: 600;
		border-radius: 2rem;
		border: solid 2px white;
		padding: .5rem .8rem;
		box-shadow: 5px 7px 15px rgba(black, .3);
		cursor: pointer;
	}

	#render-busca {
		grid-template-columns: repeat(1, 1fr);
	
		& .miniatura-imovel {
			width: 100% !important;
		}
	}

	.busca {
		display: block;
		width: 100%;
		height: 100%;
		padding: 2rem;
		text-align: center;
		border: none;
		box-shadow: none;
		position: inherit;
		top: inherit;
		left: inherit;
		transform: inherit;
		background-image: url('/jnh/header/bg_header3_mob.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;
		background-color: darken($gray1, 5);
		animation-name: bgheadermob;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-duration: 25s;

		@include keyframes('bgheadermob'){
			0% {
				background-image: url('/jnh/header/bg_header3_mob.jpg');
			}
			10% {
				background-image: url('/jnh/header/bg_header3_mob.jpg');
			}
			20%{
				background-image: url('/jnh/header/bg_header4_mob.jpg');
			}
			40%{
				background-image: url('/jnh/header/bg_header4_mob.jpg');
			}
			50% {
				background-image: url('/jnh/header/bg_header5_mob.jpg');
			}
			60% {
				background-image: url('/jnh/header/bg_header5_mob.jpg');
			}
			70% {
				background-image: url('/jnh/header/bg_header6_mob.jpg');
			}
			90% {
				background-image: url('/jnh/header/bg_header6_mob.jpg');
			}
		}

		& .container_creci_mob {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
			width: 100%;
			margin: 0;
	
			& .corretor {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				margin: 0;
	
				& .nome_corretor {
					color: white;
					font-family: 'Elsie';
					font-size: .9rem;
					font-weight: 400;
					margin: 0;
					line-height: .75rem;
				}
	
				& span {
					font-size: .75rem;
					font-weight: 500;
					color: $colorPrimary;
					margin: 0;
				}
			}
		}
	
		& h1 {
			font-size: 1.3rem;
		}

		& h2 {
			font-size: 1.1rem;
			margin-bottom: 2.5rem;
		}
	
		& .busca-filtro{
			& .bloco_busca{
				background-color: rgba($colorTerciary, .65);
				padding: 1rem;
				border-radius: 1rem;

				& .item_buscaMenu {
					& .title_is {
						& p {
							color: white;
							font-size: 1rem;
							font-weight: 500;
							padding-bottom: .3rem;
							border-bottom: solid 1px darken($gray2, 10);
							margin: 0;

							& i {
								color: $colorPrimary;
								font-size: .7rem;
								vertical-align: middle;
							}
						}
					}

					& .buscaMenu {
						& .elementos_buscaMenu_class {
							& a {
								& p {
									color: white;
									font-size: 1rem;
									font-weight: 400;
									padding-left: 1.5rem;
								}
							}
						}
					}
				}

				& .container_filtros {
					grid-template-columns: 1fr;
					background-color: transparent;
					padding: 0;

					& .item_buscahome {
						& .btn-home{
							font-size: 1.2rem;
						}
					}
				}
			}
		}
	}
}

