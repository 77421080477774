.formsimple-textarea {
	outline: none;
	width: 100%;
	padding: 1rem;
	min-height: #{$formsimple_inputHeight}px;
	min-width: #{$formsimple_inputHeight}px;
	background-color: $formsimple_inputBackgroundColor;
	border: #{$formsimple_inputBorder}px solid $formsimple_inputBorderColor;
	// border-radius: #{$formsimple_inputBorderRadius_a}px  #{$formsimple_inputBorderRadius_b}px #{$formsimple_inputBorderRadius_c}px #{$formsimple_inputBorderRadius_d}px;
	resize: none;
	margin: 0px !important;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	@include formsimple-font;

	&:focus {
		border-color: darken($formsimple_inputBorderColor, 10%);
	}

	&:disabled {
		cursor: not-allowed !important;
		background: $formsimple_inputBorderColor !important;
		color: $gray3 !important;
		border-color: $formsimple_inputBorderColor !important;
	}
}