.imo_title{
	display: block;
	width: 100%;

	& h1{
		font-size: 1.5rem;
		font-weight: 500;
		color: $gray1;
	}

	& p{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
	}
}

.btn_entrar_em_contato_mobile{
	display: block;
	width: 100%;

	& button{
		width: 100%;
		padding: .5rem 0;
		border: solid 1px $colorPrimary;
		background-color: $colorPrimary;
		color: $colorTerciary;
		font-size: 1rem;
		font-weight: 600;
		border-radius: .5rem;
		text-transform: uppercase;
		& i{
			font-weight: 600;
		}
		&:active{
			background-color: darken($colorPrimary, 5);
		}
	}
}

.galeriaa_right{
	padding: .5rem 1rem;
	width: 100%;
	border-left: solid 2px $gray4;

	& .imo_codigo{
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;

		& span{
			font-size: 1rem;
			color: $colorTerciary;
			font-weight: 500;
		}
	}

	& .acao_tipo{
		font-size: .9rem;
		color: $gray2;
		font-weight: 400;
	}

	& .tarja_valor{
		font-size: 2rem;
		color: $colorPrimary;
		font-weight: 600;
	}

	& .valor_metragem{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
	}
	
	& .valor_cond{
		width: 100%;
		display: block;
		box-sizing: border-box;

		& .titlecond{
			font-size: .9rem;
			font-weight: 500;
			color: $gray2;
			margin: 0;
			vertical-align: middle;

			& i{
				font-size: 1.1rem;
				font-weight: 500;
				vertical-align: middle;
				color: $colorPrimary;
				cursor: pointer;
			}
		}
		
		& p{
			font-size: .85rem;
			font-weight: 400;
			color: lighten($gray2, 5);
			margin: .2rem 0;
		}
	}
}


.box_promocao_interna{
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	padding: .5rem 1rem;
	border-radius: .2rem;
	margin: 0;
	
	& .title{
		font-size: 1rem;
		font-weight: 600;
		color: $colorTerciary;
	}
	
	& p{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
	}
}

.box_aceitatroca{
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	padding: .5rem 1rem;
	border-radius: .5rem;
	margin: 0;
	
	& .title{
		font-size: 1rem;
		font-weight: 600;
		color: $colorTerciary;
	}

	& p{
		font-size: .9rem;
		font-weight: 400;
		color: $gray2;
	}
}

.formulario_interno{
	background-color: white;
	padding: .5rem 1.5rem;
	border-radius: .5rem;
	border: solid 1px lighten($gray4, 10);

	& h4{
		font-size: 1.5rem;
		font-weight: 600;
		color: $colorTerciary;
	}

	& .btn_zap_interno{
		width: fit-content;
		display: block;
		padding: 0 2rem;
		background-color: #00af53;
		margin: auto;
		border-radius: 2rem;
		box-shadow: 0 0 5px 0 rgba(#00af53, .5);
		cursor: pointer;			

		& p{
			text-align: center;
			color: white;
			font-size: 1.2rem;
			font-weight: 600;
			padding: .5rem 0;
			vertical-align: middle;
			user-select: none;

			& i{
				vertical-align: middle;
				font-size: 1.8rem;
				font-weight: 400;
			}
		}
		& .pulse-button:hover {
			-webkit-animation: none;
		}


		@-webkit-keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 10px rgba(#00af53, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#00af53, 0);
			}
		}
	}

	& .section_dialog2{
		display: block;
		width: 100%;
		border: solid 1px lighten($gray4, 10);
		padding: 1rem;
		border-radius: .2rem;
		background-color: white;

		& .btn_fechar_form_zap{
			display: flex;
			position: absolute;
			top: -1.8rem;
			right: -1.8rem;
			background-color: white;
			border: solid 2px #00af53;
			padding: .5rem;
			border-radius: 50%;
			width: 2.1rem;
			height: 2.1rem;
			align-items: center;
			justify-content: center;
			transition: all .2s ease-in-out;
			cursor: pointer;
			& i{
				margin: 0;
				padding: 0;
				line-height: initial;
				color: $gray3;
				transition: all .2s ease-in-out;
			}

			&:hover{
				background-color: #00af53;
				& i{
					color: white;
				}
			}
		}

		& .dialog_wpp{
			display: block;
			width: 100%;

			& h2{
				font-size: .9rem;
				color: $gray1;
				text-align: center;
				margin: auto;
				max-width: 80%;
			}

			& .inputs_wpp{
				display: block;
				width: 100%;

				& .inputs_dados{
					display: block;
					width: 100%;

					& input{
						border: solid 1px lighten($gray4, 10);
						border-radius: .2rem;
						padding: .5rem;
						width: 100%;
					}
				}

				& .input_msg{
					display: block;
					width: 100%;

					& input{
						border: solid 1px lighten($gray4, 10);
						border-radius: .2rem;
						padding: .5rem;
						width: 100%;
					}

					& button{
						width: 100%;
						display: block;
						background-color: #00af53;
						color: white;
						font-size: .9rem;
						font-weight: 600;
						padding: .5rem 0;
						border: none;
						border-radius: .2rem;
						cursor: pointer;
						transition: all .2s ease-in-out;

						& i{
							font-weight: 400;
						}

						&:hover{
							background-color: darken(#00af53, 5);
						}
					}
				}
			}
		}
	}

	& .formulario-titulo{
		color: $gray2;
		font-size: .85rem !important;
		font-weight: 400;
	}
}
.texto_descricao_imo{
	max-width: 55rem;
	margin-left: 0;
	line-height: 1.3rem;
	font-size: 1rem;

	& h3{
		font-size: 1rem;
		font-weight: 400;
		color: $gray2;
		line-height: 1.5rem;
	}
}

.width_pc_mobile{
	width: 55% !important;
}

.share_session{
	background-color: white;
	border-radius: 0 .5rem .5rem 0;
	border-top: solid 1px lighten($gray4, 10);
	border-right: solid 1px lighten($gray4, 10);
	border-bottom: solid 1px lighten($gray4, 10);
	display: inline-block;
	vertical-align: middle;
	width: 60%;
	padding: 0.15rem 0;
	& .item2{
		& p{
			padding: 0;
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item{
		display: inline-block;
		text-align: center;
		width: 20%;
		//padding: 0 .5rem;
	}
}

.imprimir_session{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	display: inline-block;
	vertical-align: middle;
	border-radius: .5rem 0 0 .5rem;
	width: 40%;
	padding: .15rem 0;
	& .item{
		display: inline-block;
		text-align: center;
		width: 55%;
		& p{
			margin-left: 0.7rem;
			margin-right: 0.7rem;
			display: inline-block;
			color: $gray3;
			font-size: 1rem;
			font-weight: 600;
		}
	}
	& .item2{
		display: inline-block;
		text-align: center;
		width: 45%;
	}
}

/* 1200px */
@media all and (min-width: 1024px) and (max-width: 1380px){
	
	.imprimir_session{
		background-color: white;
		border: solid 1px $gray4;
		display: inline-block;
		vertical-align: middle;
		border-radius: .2rem 0 0 .2rem;
		width: 40%;
		padding: .1rem 0;
		& .item{
			display: inline-block;
			text-align: center;
			width: 55%;
			& p{
				margin-left: 0.7rem;
				margin-right: 0.7rem;
				display: inline-block;
				color: $gray3;
				font-size: 1rem;
				font-weight: 600;
			}
		}
		& .item2{
			display: inline-block;
			text-align: center;
			width: 45%;
		}
	}
}



/* MOBILE */
@media all and (max-width: 1023px){

	.imo_title{
		& h1{
			font-size: 1.1rem;
		}

		& p{
			font-size: .9rem;
		}
	}

	.galeriaa_right{
		padding: .5rem 1rem;
		width: 100%;
		border-left: solid 2px $gray4;
		& .tarja_valor{
			font-size: 2rem;
		}
	}

	.formulario_interno{
		& h4{
			font-size: 1.5rem;
		}
		
	}
	.texto_descricao_imo{
		width: 100%;
		line-height: 1.3rem;
		font-size: 1rem;
	}

	.width_pc_mobile{
		width: 100% !important;
	}

	.share_session{
		background-color: white;
		border-radius: 0 .2rem .2rem 0;
		border: solid 1px lighten($gray4, 10);
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		& .item2{
			& p{
				padding: 0;
				margin-left: 0.7rem;
				margin-right: 0.7rem;
				display: inline-block;
				color: $gray3;
				font-size: 1rem;
				font-weight: 600;
			}
		}
		& .item{
			display: inline-block;
			text-align: center;
			width: 20%;
		}
	}
	
	.avaliar_session{
		width: 100%;
		vertical-align: middle;
		display: inline-block;
		border-radius: 0 .2rem .2rem 0;
		border: solid 1px $gray4;
		& .item{
			background-color: white;
			width: 100%;
			border-radius: 0 .2rem .2rem 0 ;
			
			& p{
				margin: 0;
				color: $gray3;
				text-align: center;
				font-size: 1.2rem;
			}
		}
		& .b_avaliar_imo{
			background-color: white;
			border: solid 2px transparent;
			color: $colorPrimary;
			padding: 0 .5rem;
			cursor: pointer;
		}
	}

}